
import { createElement, PureComponent } from 'react';
import { navigate } from 'gatsby'
import { connect } from 'react-redux';
import { Map } from 'immutable';

import { forceReset, selectSurvey } from '../stores/surveyStore';

class Reset extends PureComponent {
  componentDidMount() {
    const { reset } = this.props;
    if (this.shouldReset()) {
      reset();
    } else {
      this.redirect();
    }
  }

  componentDidUpdate() {
    if (!this.shouldReset()) this.redirect();
  }

  shouldReset = () => {
    const { answers, pageContext: { index } } = this.props;
    if (typeof index === 'number' && Map.isMap(answers)) {
      return answers.some((value, key) => {
        const [id] = key.split('-');
        return id >= index && typeof value !== 'undefined';
      })
    } else {
      return typeof answers !== 'undefined';
    }
  }

  redirect = () => {
    const { pageContext: { slug, index } } = this.props;
    if (typeof index === 'number') {
      navigate(`/${slug}/${index + 1}/`, { replace: true });
    } else {
      navigate(`/${slug}/`, { replace: true });
    }
  }

  render() {
    return null;
  }
}

export default (props) => {
  const { slug, index } = props.pageContext;
  return createElement(connect(
    selectSurvey(slug),
    dispatch => ({ reset: () => dispatch(forceReset({ slug, index })) })
  )(Reset), props);
};

